import React, { useState } from 'react';
import { useHookstate } from '@hookstate/core';
import { isDesktop } from 'react-device-detect';
import GlobalState from '../src/shared/states/global_state';
import FeedbackForm from './components/feedbackForm';

export default function Feedback({ children }) {
  const globalState = useHookstate(GlobalState);
  const { displayFeedbackForm } = globalState.get();

  return (
    <>
      {children}

      <FeedbackForm
        show={displayFeedbackForm}
        onHide={() => globalState.merge({ displayFeedbackForm: false })}
      />
    </>
  );
}
