import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { useHookstate } from '@hookstate/core';

import { fetchData } from '../src/shared/global_functions';
import GlobalState from './shared/states/global_state';
import { deleteData } from './shared/global_functions';
import styles from '../styles/Header.module.scss';
import ZetteLogo from '../public/zette_logo.svg';
import { ChevronDown } from 'react-bootstrap-icons';
import FreeCreditModal from '../src/components/freeCreditModal';
import { getLinkByBrowser } from '../src/components/utils';

const AdminPanelNavItem = ({ user }) =>
  user &&
  !!user.roles['admin'] && (
    <Button
      variant="outline-secondary btn-sm"
      className="rounded-pill pt-2 px-2 mx-3"
      href="/admin"
    >
      Admin Panel
    </Button>
  );

export const ProfileNavItem = ({ user, userProfileImage, type }) => {
  const containerClasses = classNames(
    styles.zette_profile_btn,
    type == 'profile' ? styles.zette_profile_btn_big : null,
    "d-flex align-items-center justify-content-center text-decoration-none"
  );

  return (
    <>
      <div className={containerClasses}>
        {userProfileImage && userProfileImage.url != '' ? (
          <Link className={styles.nostyle} href="/users/profile">
            <Image
              src={userProfileImage?.url}
              className={styles.avatar}
              alt="Picture of the user"
              width={100}
              height={100}
            />
          </Link>
        ) : (
          <Link className={styles.nostyle} href="/users/profile">
            <span className={styles.profile_first_name_letter}>
              {user && user.firstName ? user.firstName[0] : '-'}
            </span>
          </Link>
        )}
      </div>

      {/*<div className="px-2 d-flex align-items-center justify-content-center">
      <Link className={styles.nostyle} href="/users/profile">
        <span>
          {user.firstName} {user.lastName}
        </span>
      </Link>
      </div>*/}
    </>
  );
};

const ProfileDropdown = ({ handleSignout, onHideFreeCredit }) => {
  const globalState = useHookstate(GlobalState);
  const { creditsRemaining, userProfileImage } = globalState.get();

  return (
    <Dropdown align="end" className="d-flex align-items-center ms-2">
      <Dropdown.Toggle variant="button" bsPrefix="p-0">
        <ChevronDown></ChevronDown>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropDownMenu}>
        <NavDropdown.Item href="/">Home</NavDropdown.Item>
        <NavDropdown.Item href="/users/profile">Account</NavDropdown.Item>
        <NavDropdown.Item href="/users/creditsandbilling">
          Credits & Billing
        </NavDropdown.Item>
        <Dropdown.Divider />
        <NavDropdown.Header className={styles.itemMenuText}>
          <span>Current plan: free</span>
          <span>{`${creditsRemaining} Credits Remaining`}</span>
        </NavDropdown.Header>
        <NavDropdown.Item onClick={onHideFreeCredit}>
          Get Free Credits
        </NavDropdown.Item>
        <NavDropdown.Item href={getLinkByBrowser()} target="_blank">
          Install the Extension
        </NavDropdown.Item>
        <NavDropdown.Item href="https://support.zette.com" target="_blank">
          Help
        </NavDropdown.Item>
        <Dropdown.Divider />
        <NavDropdown.Item href="#" onClick={handleSignout}>
          Sign out
        </NavDropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CreditsBanner = ({ creditsRemaining }) => (
  <div className="d-flex align-items-center">
    <div className="d-flex align-items-center me-3">
      <img src="/zette_credit_logo.svg" height="14px" />
    </div>

    <span>
      {creditsRemaining} {creditsRemaining === 1 ? 'credit' : 'credits'}{' '}
      available
    </span>
  </div>
);

interface LoggedInHeaderProps {
  user: any;
  onSignout: () => void;
  title?: string;
  subtitle?: string;
  showCredits?: boolean;
  handleHideFreeCredit?: () => void;
}

function LoggedInHeader({
  user,
  onSignout,
  title,
  subtitle,
  showCredits,
  handleHideFreeCredit,
}: LoggedInHeaderProps) {
  const globalState = useHookstate(GlobalState);
  const { creditsRemaining, userProfileImage } = globalState.get();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const { data } = await fetchData({ url: '/current_user' });
    if (data.user) {
      globalState.merge({
        user: data.user,
        userInterests: data.userInterests,
        userProfileImage: data.userProfileImage,
        creditsRemaining: parseInt(data.creditsRemaining, 10),
      });
    }
  };

  const handleSignout = async () => {
    try {
      await deleteData({ url: '/sessions/logout' });

      onSignout();
    } catch (error) {
      return error.response;
    }
  };

  return (
    <div>
      <Navbar
        variant="light"
        expand="lg"
        className={[styles.navbar, !title ? styles.navbarShort : ''].join(' ')}
      >
        <Container fluid="xxxl" className="px-5 py-2 mt-4">
          <Link href="/" passHref={true}>
            <Navbar.Brand>
              <Image src={ZetteLogo} alt="Zette logo" />
            </Navbar.Brand>
          </Link>

          <Nav className="ms-auto flex-row">
            <AdminPanelNavItem user={user} />
            {user && !user.roles['admin'] && (
              <Button
                variant="outline-secondary btn-sm"
                className="rounded-pill pt-1 px-3 mx-3"
                onClick={() => globalState.merge({ displayFeedbackForm: true })}
              >
                Give Feedback
              </Button>
            )}

            <CreditsBanner creditsRemaining={creditsRemaining} />
            <ProfileNavItem
              user={user}
              userProfileImage={userProfileImage}
              type="header"
            />
            <ProfileDropdown
              handleSignout={handleSignout}
              onHideFreeCredit={handleHideFreeCredit}
            />
          </Nav>
        </Container>

        {title && (
          <Container className="mt-5">
            <h1 className={styles.title}>{title}</h1>
          </Container>
        )}

        {subtitle && (
          <Container>
            <span className={styles.subtitle}>{subtitle}</span>
          </Container>
        )}
      </Navbar>
    </div>
  );
}

export function ProfileHeader({
  user,
  onSignout,
  showCredits,
  handleHideFreeCredit,
}: LoggedInHeaderProps) {
  const globalState = useHookstate(GlobalState);
  const { creditsRemaining, userProfileImage } = globalState.get();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const { data } = await fetchData({ url: '/current_user' });
    if (data.user) {
      globalState.merge({
        user: data.user,
        userInterests: data.userInterests,
        userProfileImage: data.userProfileImage,
        creditsRemaining: parseInt(data.creditsRemaining, 10),
      });
    }
  };

  const handleSignout = async () => {
    try {
      await deleteData({ url: '/sessions/logout' });

      onSignout();
    } catch (error) {
      return error.response;
    }
  };

  return (
    <div>
      <Navbar variant="light" expand="lg">
        <Container fluid="xxxl" className="px-5 py-2 mt-4">
          <Link href="/" passHref={true}>
            <Navbar.Brand>
              <Image src={ZetteLogo} alt="Zette logo" />
            </Navbar.Brand>
          </Link>

          <Nav className="ms-auto flex-row">
            <CreditsBanner creditsRemaining={creditsRemaining} />

            <ProfileNavItem
              user={user}
              userProfileImage={userProfileImage}
              type="header"
            />
            <ProfileDropdown
              handleSignout={handleSignout}
              onHideFreeCredit={handleHideFreeCredit}
            />
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

function LoggedOutHeader() {
  return (
    <div>
      <Navbar bg="light" variant="light" expand="lg" className="shadow-sm">
        <Container fluid="xxxl" className="px-5 py-2">
          <Link href="/" passHref={true}>
            <Navbar.Brand>
              <Image src={ZetteLogo} alt="Zette logo" />
            </Navbar.Brand>
          </Link>

          <Navbar.Toggle aria-controls="header-nav" />

          <Navbar.Collapse id="header-nav">
            <Nav className="ms-auto">
              <Link href="/extension" passHref={true}>
                <Nav.Link>Get Extension</Nav.Link>
              </Link>

              <Link href="/signin" passHref={true}>
                <Nav.Link>Sign in</Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default function Header({ title, subtitle, showCredits, type }) {
  const globalState = useHookstate(GlobalState);
  const { user } = globalState.get();
  const [freeCreditModalShow, setFreeCreditModalShow] = useState(false);
  const router = useRouter();

  const onSignout = () => {
    globalState.merge({ user: null, userInterests: [] });
    router.replace('/signin');
  };

  const handleHideFreeCredit = () => {
    setFreeCreditModalShow(false);
  };

  const handleFreeCredit = () => {
    setFreeCreditModalShow(true);
  };

  return (
    <>
      {user ? (
        type == 'profile' ? (
          <ProfileHeader
            user={user}
            onSignout={onSignout}
            showCredits={showCredits}
            handleHideFreeCredit={handleFreeCredit}
          />
        ) : (
          <LoggedInHeader
            user={user}
            onSignout={onSignout}
            title={title}
            subtitle={subtitle}
            showCredits={showCredits}
            handleHideFreeCredit={handleFreeCredit}
          />
        )
      ) : (
        <LoggedOutHeader />
      )}
      <FreeCreditModal
        show={freeCreditModalShow}
        onHide={handleHideFreeCredit}
      />
    </>
  );
}

export function LogoHeader({ children }) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar bg="light" variant="light" expand="lg">
        <Container className="py-2">
          <Link href="/" passHref={true}>
            <Navbar.Brand>
              <Image src={ZetteLogo} alt="Zette logo" />
            </Navbar.Brand>
          </Link>
        </Container>
      </Navbar>
      <Container>{children}</Container>
    </div>
  );
}
