import React, { ReactElement, ReactNode } from 'react';
import type { AppProps } from 'next/app';
import { NextPage } from 'next';
import Layout from '../src/Layout';

import '../styles/globals.scss';
import { NextPageWithLayout } from '../src/shared/types';
import { ThemeProvider } from 'react-bootstrap';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  const ComponentWithLayout = getLayout(<Component {...pageProps} />);
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      {ComponentWithLayout}
    </ThemeProvider>
  );
}
