import Header from './Header';
import Footer from './Footer';
import React from 'react';
import { Container } from 'react-bootstrap';
import Feedback from './Feedback';
import { useRouter } from 'next/router';
import { useHookstate } from '@hookstate/core';
import GlobalState from './shared/states/global_state';

export default function Layout({ children, additionalRouteInfo = [] }) {
  const router = useRouter();
  const currentRoute = router.pathname;

  const user = useHookstate(GlobalState).get().user;
  const defaultRouteInfo = [
    {
      path: /\/$/,
      title: user ? `Welcome back, ${user.firstName}!` : 'Welcome Back!',
      subtitle:
        'Bookmark and share any articles from your reading history below.',
      showCredits: true,
    },
  ];

  const matchedRoute =
    [...defaultRouteInfo, ...additionalRouteInfo].find((route) =>
      currentRoute.match(route.path)
    ) ?? {};

  return (
    <div className="d-flex flex-column min-vh-100">
      <Feedback>
        <Header {...matchedRoute} />
        <Container fluid="xxxl" className="py-3 px-5 flex-grow-1">
          {children}
        </Container>
        <Footer />
      </Feedback>
    </div>
  );
}
