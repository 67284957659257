import { isChrome, isChromium, isFirefox, isEdge } from 'react-device-detect';

function addZero(d: Number) {
  return d <= 9 ? '0' + d : d;
}

export function dateToYMDMS(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  var min = date.getMinutes();
  var h = date.getHours();

  return `${y}-${addZero(m)}-${addZero(d)}-${addZero(h)}_${addZero(min)}`;
}

export function getLinkByBrowser() {
  if (isChrome || isChromium) {
    return "https://chrome.google.com/webstore/detail/zette/ejjbjmnpcbnmldpcfiealfpmhnahmbdh";
  } else if (isFirefox) {
    return "https://addons.mozilla.org/en-US/firefox/addon/zette";
  } else if (isEdge) {
    return "https://chrome.google.com/webstore/detail/zette/ejjbjmnpcbnmldpcfiealfpmhnahmbdh";
  } else {
    return "https://chrome.google.com/webstore/detail/zette/ejjbjmnpcbnmldpcfiealfpmhnahmbdh";
  }
}
