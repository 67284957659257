import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../../styles/components/ArticleCard.module.scss';
import classNames from 'classnames';
import { X } from 'react-bootstrap-icons';

export interface FreeCreditModalProps {
    show: boolean;
    onHide: () => void;
  }

  export default function FreeCreditModal({
    show,
    onHide,
  }: FreeCreditModalProps) {

   
   return(
    <Modal show={show} onHide={onHide} centered>
        
          <div className={styles.shareContainer}>
            <img
              className={styles.imgThumbnail}
              src="/img/freeCredits.png"
              alt="Article Thumbnail"
            />
            <div
              className={classNames(styles.imgThumbnail)}
            ></div>
            <div className={styles.textContainer}>
              <h2>Get Free Credits</h2>
              <span>
                Share articles with your friends and <span>earn a free credit </span> for each friend that signs up
                to Zette for the first time.
              </span>
            </div>
            <button className={styles.btnClose} onClick={onHide}>
              <X size={62} color={'#000'} />
            </button>
          </div>
    </Modal>
   )

  }