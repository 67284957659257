import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../shared/constants';
import { User } from '../../additional';

const ZETTE_PROJECT = 'zetteWebApp';
const { NODE_ENV } = process.env;

const isProduction = NODE_ENV == 'production';

export enum AnalyticsEvent {
  // Spontaneous / System events
  Impression = 'impression',
  View = 'view',

  // User engagement events
  Close = 'clickClose',
  Primary = 'clickPrimary',
  Secondary = 'clickSecondary',
}

export function track(object: string, event: AnalyticsEvent, extraData = {}) {
  const identifier = `${ZETTE_PROJECT}:${object}:${event}`;
  mixpanel.track(identifier, extraData);
}

export function identify(user: User) {
  const { firstName, lastName, email } = user;
  const fullName = `${firstName} ${lastName}`;

  mixpanel.identify(email);
  mixpanel.people.set({
    distinct_id: email,
    name: fullName,
    email: email,
  });
}

mixpanel.init(MIXPANEL_TOKEN, { debug: isProduction ? false : true });
