import { createState } from '@hookstate/core';

const GlobalState = createState({
  user: null,
  userInterests: [],
  userProfileImage: null,
  creditsRemaining: 0,
  displayFeedbackForm: false,
});

export default GlobalState;
