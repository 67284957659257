import React from 'react';

interface Props {
  errors?: Array<string>;
}

export default function Errors({ errors }: Props) {
  if (!errors || errors.length < 1) return null;

  return (
    <div className="text-danger mb-3">
      {errors.map((message, index) => {
        return <div key={index}>{message}</div>;
      })}
    </div>
  );
}
