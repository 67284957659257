import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from '../../styles/FeedbackForm.module.scss';
import stylesModal from '../../styles/components/modal.module.scss';
import { Button, Spinner } from 'react-bootstrap';
import IconClose from '../../public/img/IconClose.svg';
import Image from 'next/image';
import { postData } from '../../src/shared/global_functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormErrors from '../../src/shared/FormErrors';
import { track, AnalyticsEvent } from '../shared/analytics';

const FeedbackObject = 'feedback';

const FeedbackForm = (props) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [satisfaction, setSatisfaction] = useState(null);

  const onSunmit = async (e) => {
    e.preventDefault();
    if (satisfaction == null) {
      setErrors(['Please tell us how satisfied you are with Zette']);
      throw '';
    }
    setIsLoading(true);

    const data = {
      comment,
      satisfaction,
      //include this parameter if you want to categorize the feedback
      category: 'normal',
    };

    let result;

    try {
      const response = await postData({
        url: '/feedback/create',
        data,
      });

      result = response.data;
    } catch (e) {
      result = e.response.data;
    }

    setIsLoading(false);

    if (result.errors) {
      setErrors(result.errors);
      track(`${FeedbackObject}_error`, AnalyticsEvent.Impression);
    } else {
      track(FeedbackObject, AnalyticsEvent.Primary, {
        satisfaction,
      });
      toast.success('Your feedback has been submitted successfully!');
      setComment('');
      setSatisfaction(null);
      props.onHide();
    }
  };

  useEffect(() => {
    track(FeedbackObject, AnalyticsEvent.View);
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={styles.modalCustom}
        centered
      >
        <div className={styles.main}>
          <div className={stylesModal.contanierTitle}>
            <div className={stylesModal.title}>Share your feedback</div>
            <button className={stylesModal.btnClose} onClick={props.onHide}>
              <Image src={IconClose} />
            </button>
          </div>
          <div>
            <div className={styles.textFeed}>
              How satisfied are you with Zette?{' '}
              <span style={{ color: 'red' }}>*</span>
            </div>
            <div className={styles.containerFeedbackBtn}>
              <button
                className={`
                                ${styles.btnEmoji} 
                                ${
                                  satisfaction !== null && satisfaction != 2
                                    ? styles.unSelectEmoji
                                    : ''
                                }`}
                onClick={() => setSatisfaction(2)}
              >
                😄
              </button>
              <button
                className={`
                                ${styles.btnEmoji} 
                                ${
                                  satisfaction !== null && satisfaction != 1
                                    ? styles.unSelectEmoji
                                    : ''
                                }`}
                onClick={() => setSatisfaction(1)}
              >
                🙂
              </button>
              <button
                className={`
                                ${styles.btnEmoji} 
                                ${
                                  satisfaction !== null && satisfaction != 0
                                    ? styles.unSelectEmoji
                                    : ''
                                }`}
                onClick={() => setSatisfaction(0)}
              >
                😐
              </button>
              <button
                className={`
                                ${styles.btnEmoji} 
                                ${
                                  satisfaction !== null && satisfaction != -1
                                    ? styles.unSelectEmoji
                                    : ''
                                }`}
                onClick={() => setSatisfaction(-1)}
              >
                🙁
              </button>
              <button
                className={`
                                ${styles.btnEmoji} 
                                ${
                                  satisfaction !== null && satisfaction != -2
                                    ? styles.unSelectEmoji
                                    : ''
                                }`}
                onClick={() => setSatisfaction(-2)}
              >
                ☹️
              </button>
            </div>
          </div>

          <div>
            <div className={styles.textFeed}>Any comment</div>
            <textarea
              className={styles.textAreaFeed}
              placeholder="Tell us about your experience (optional)"
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          {<FormErrors errors={errors} />}
          <div className={styles.containerAction}>
            <Button
              className={`${styles.btn} ${styles.cancelBtn}`}
              variant="light"
              onClick={props.onHide}
            >
              Cancel
            </Button>
            <Button
              className={`${styles.btn} ${styles.btnSubmit}`}
              onClick={onSunmit}
              disabled={satisfaction == null || isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default FeedbackForm;
