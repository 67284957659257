// @ts-nocheck - Axios currently has typing issues with headers: https://github.com/axios/axios/issues/4193
import axios from 'axios';
import { SERVER_API_BASE_URL } from './constants';

const axiosInstance = axios.create({
  baseURL: SERVER_API_BASE_URL,
  withCredentials: true,
});

// Set the CSRF token passed in every response
axiosInstance.interceptors.response.use((response) => {
  const { data } = response;

  if (data && data.csrfToken) {
    window._csrf = data.csrfToken;
  }

  return response;
});

// Gets the CSRF token for forms
export function csrfTokenHeaders() {
  const csrfToken = window._csrf;

  return {
    'X-CSRF-Token': csrfToken,
  };
}

interface FetchParams {
  url: string;
  params?: object;
  headers?: object;
  signal?: AbortSignal;
}

export function fetchData({
  url,
  params,
  headers,
  signal = null,
}: FetchParams) {
  return axiosInstance.get(url, {
    headers: { accept: 'application/json', ...headers },
    params,
    signal: signal,
  });
}

interface APIParams {
  url: string;
  data?: object;
  headers?: object;
  signal?: AbortSignal;
}

export function postData({ url, data, headers, signal = null }: APIParams) {
  return axiosInstance.post(url, data, {
    headers: {
      accept: 'application/json',
      common: { ...csrfTokenHeaders() },
      ...headers,
    },
    signal: signal,
  });
}

export function patchData({ url, data, headers, signal = null }: APIParams) {
  return axiosInstance.patch(url, data, {
    headers: {
      accept: 'application/json',
      common: { ...csrfTokenHeaders() },
      ...headers,
    },
    signal: signal,
  });
}

interface DeleteParams {
  url: string;
  params?: object;
  headers?: object;
  signal?: AbortSignal;
}

export function deleteData({ url, headers, signal = null }: DeleteParams) {
  return axiosInstance.delete(url, {
    headers: {
      accept: 'application/json',
      common: { ...csrfTokenHeaders() },
      ...headers,
    },
    signal: signal,
  });
}
