import React from 'react';
import classnames from 'classnames';

import styles from '../styles/Footer.module.scss';
import ZetteLogo from '../public/zette_logo.svg';
import Image from 'next/image';

export default function Footer() {
  const footerHeadingClass = classnames(styles.header);

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={`${styles.footerColumn}`}>
          <Image src={ZetteLogo} alt="Zette logo" />
        </div>

        <div className={`${styles.footerColumn}`}>
          <div className={footerHeadingClass}>Company</div>

          <a className={styles.a} href="https://www.zette.com" target="_blank">
            About Us
          </a>

          <a
            className={styles.a}
            href="https://www.zette.com/terms-of-service"
            target="_blank"
          >
            Term of Service
          </a>

          <a
            className={styles.a}
            href="https://www.zette.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>

          <a className={styles.a} href="/contact">
            Contact Us
          </a>

          <a
            className={styles.a}
            href="https://zette.com/careers"
            target="_blank"
          >
            Careers
          </a>
        </div>

        <div className={`${styles.footerColumn}`}>
          <div className={footerHeadingClass}>Follow Zette</div>
          <a
            className={styles.a}
            href="https://www.facebook.com/zettemedia/"
            target="_blank"
          >
            Facebook
          </a>
          <a
            className={styles.a}
            href="https://www.instagram.com/zettemedia/?hl=en"
            target="_blank"
          >
            Instagram
          </a>
          <a
            className={styles.a}
            href="https://twitter.com/zettemedia?lang=en"
            target="_blank"
          >
            Twitter
          </a>
          <a
            className={styles.a}
            href="https://www.linkedin.com/company/zette/"
            target="_blank"
          >
            LinkedIn
          </a>
        </div>

        <div className={`${styles.footerColumn}`}>
          <div className={footerHeadingClass}>Resources</div>

          <a
            className={styles.a}
            href="https://zette.notion.site/f13526c389e640589687e4efe96b9328?v=9aec912906b54e42a58c5b525259de09"
            target="_blank"
          >
            List of Publishers
          </a>

          <a
            className={styles.a}
            href="https://support.zette.com"
            target="_blank"
          >
            Get Help
          </a>

          <a
            className={styles.a}
            href="https://tally.so/r/wgDo8D"
            target="_blank"
          >
            Report a Bug
          </a>
        </div>
      </div>
    </div>
  );
}
